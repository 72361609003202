<template>
  <div id="pre_register_list">
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>Clinic</label>
            <v-select
              v-model="clinic"
              :options="clinicOptions"
              class="w-100"
              :reduce="name => name.code"
              label="name"
              placeholder="select clinic"
              @input="changeRoute()"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>Doctor</label>
            <v-select
              v-model="doctor"
              :options="doctorOptions"
              class="w-100"
              :disabled="clinic === ''"
              :reduce="name => name.code"
              label="name"
              placeholder="select doctor"
              @input="getPatientLists()"
            />
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="6"
          >
            <b-form-group>
              <b-form-input
                v-model="hn"
                placeholder="HN"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="getPatientLists()"
            >
              ค้นหา
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          md="6"
          sm="12"
          class="my-1"
        />
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="patientLists"
            :fields="visibleFields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            @row-clicked="showNewPatientForm"
          >
            <template #cell(actions)>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                pill
                size="sm"
                @click="showSendToRoom(5)"
              >
                SendToRoom
              </b-button>
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="6"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <!-- Modal Send To Room  -->
    <b-modal
      id="formSendToRoomModal"
      hide-footer
      centered
      size="lg"
      title="Send Patient to Rooom"
    >
      <b-row class="mb-2">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="outline-primary"
            @click="sendToRoom(1)"
          >
            Room 1
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="outline-primary"
            @click="sendToRoom(2)"
          >
            Room 2
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="outline-primary"
            @click="sendToRoom(3)"
          >
            Room 3
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="outline-primary"
            @click="sendToRoom(4)"
          >
            Room 4
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="outline-primary"
            @click="sendToRoom(5)"
          >
            Room 5
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="outline-primary"
            @click="sendToRoom(6)"
          >
            Room 6
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Modal Appointment Lists-->
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      roomNumber: 1,
      hn: '',
      clinic: '',
      doctor: '',
      clinicOptions: [],
      doctorOptions: [],
      doctorCodeID: '',
      doctorCodeIDLists: [],
      preID: '',
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'id', label: 'Id', visible: true },
        { key: 'vn', label: 'VN', visible: true },
        { key: 'hn', label: 'HN', visible: true },
        { key: 'name', label: 'Name', visible: true },
        { key: 'gender', label: 'Gender', visible: true },
        { key: 'paymentMethod', label: 'Payment method', visible: true },
        { key: 'clinic', label: 'Clinic', visible: true },
        { key: 'doctor', label: 'Doctor in charge', visible: true },
      ],
      fieldsEnroll: [
        { key: 'queue', label: 'Queue', visible: true },
        { key: 'queueID', label: 'queueID', visible: false },
        { key: 'vn', label: 'VN', visible: true },
        { key: 'hn', label: 'HN', visible: true },
        { key: 'name', label: 'Name', visible: true },
        { key: 'actions', visible: true },
      ],
      items: [
        {
          id: 1,
          visitNumber: '003',
          hn: '0000007 ',
          name: 'Bill gate',
          gender: 'Male',
          paymentMethod: 'ผู้ป่วยทั่วไป (ชำระเงินเอง OPD)',
          clinic: 'MED AA01',
          doctor: 'DR01 Dr. Steeve Roger',
          status: 2,
        },
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      patientLists: [],
      QueueOPDID: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    visibleFields() {
      return this.fieldsEnroll.filter(field => field.visible)
    },
  },
  created() {
    this.getClinic()
    if (this.$route.params.clinic && this.$route.params.clinic !== 'null') {
      this.clinic = this.$route.params.clinic
      this.getDoctorByClinic()
      if (this.$route.params.doctor) {
        this.doctor = this.$route.params.doctor
        this.getPatientLists()
      }
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    changeRoute() {
      this.$router.push(`/nurse/patients/${this.clinic}`).catch(() => {})
      this.patientLists = []
      this.getDoctorByClinic()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getDoctorCodeIDByCode() {
      // // console.log(this.doctorCodeIDLists)
      for (let i = 0; i < this.doctorCodeIDLists.length; i += 1) {
        if (this.doctorCodeIDLists[i].code === this.doctor) {
          return this.doctorCodeIDLists[i].id
        }
      }
      return ''
    },
    showNewPatientForm() { },
    getClinic() {
      this.$http({
        url: 'http://192.168.100.147/api/ClinicDoctor/getClinic',
        method: 'GET',
        data: {},
      }).then(({ data }) => {
        const result = []
        for (let i = 0; i < data.length; i += 1) {
          const clinicname = `${data[i].clinicCode} - ${data[i].clinicName}`
          result.push({ id: i + 1, code: data[i].clinicCode, name: clinicname })
        }
        this.clinicOptions = result
      })
    },
    getDoctorByClinic() {
      this.doctor = null
      this.isScheduleHeaderActive = false
      this.isScheduleMenuActive = false
      this.$http({
        url: `http://192.168.100.147/api/Doctor/All/${this.clinic}`,
        method: 'GET',
        data: {},
      }).then(({ data }) => {
        const result = []
        const result2 = []
        for (let i = 0; i < data.length; i += 1) {
          const docname = `${data[i].docCode} - ${data[i].docName}`
          result.push({ id: i + 1, code: data[i].docCode, name: docname })
          result2.push({ id: data[i].doctorCodeID, code: data[i].docCode })
        }
        this.doctorOptions = result
        this.doctorCodeIDLists = result2
      })
    },
    getPatientLists() {
      this.scheduleId = 0
      this.patientLists = []
      this.doctorCodeID = this.getDoctorCodeIDByCode()
      this.$http({
        url: '/api/Enroll/EnrollDetail',
        method: 'POST',
        data: {
          HN: '',
          ClinicID: 0,
          DoctorCodeID: this.doctorCodeID,
          DateFilter: 20210430,
        },
      }).then(({ data }) => {
        this.patientLists = []
        const enrolls = data
        for (let i = 0; i < enrolls.length; i += 1) {
          this.patientLists.push({
            queue: enrolls[i].services.queueDetail.queueNum,
            queueId: enrolls[i].services.queueDetail.queueOPDID,
            vn: enrolls[i].patient_Visit.vn,
            hn: enrolls[i].patient.hn,
            name: enrolls[i].patientName,
            actions: enrolls[i].services.queueDetail.queueOPDID,
          })
        }
      })
    },
    getFollowUpDetail(scheduleID) {
      this.$http({
        url: '/api/Appointment/AppointmentDetail',
        method: 'POST',
        data: {
          DateFilter: this.dateFilter,
          PatientID: 0,
          ScheduleID: scheduleID,
        },
      }).then(({ data }) => {
        for (let i = 0; i < data.length; i += 1) {
          this.patientLists.push(
            {
              id: i + 1,
              date: data[i].appointment.appointmentDate.substr(0, 10),
              time: data[i].appointment.rangeAppointment,
              hn: data[i].patient.hn,
              name: `${data[i].patient.fName} ${data[i].patient.lName}`,
              by: data[i].appointment.updateBy,
              updateDate: data[i].appointment.updateDate.substr(0, 10),
              appointmentID: data[i].appointment.appointmentID,
              patientID: data[i].appointment.patientID,
              scheduleID: data[i].appointment.scheduleID,
              appointmentNote: data[i].appointment.appointmentNote,
              comeForCode: data[i].appointment.comeForCode,
              actions: null,
            },
          )
        }
      })
    },
    showSendToRoom(arg) {
      // console.log(arg)
      this.QueueOPDID = arg
      this.$bvModal.show('formSendToRoomModal')
    },
    sendToRoom(room) {
      // console.log('Send')
      this.$http({
        url: '/api/Enroll/SendQueueToCall',
        method: 'POST',
        data: {
          QueueOPDID: 5,
          DoctorCodeID: this.doctorCodeID,
          RoomNumber: room,
        },
      })
    },
  },
}
</script>
